import React, { useState } from 'react';
import { StyleSheet, View, Platform, ScrollView } from 'react-native';
import { Divider, Text, TextInput } from 'react-native-paper';

let Youtube;
if (Platform.OS === "web") {
  Youtube = require('../../../components/WebYoutube').default
} else {
  Youtube = require('../../../components/MobileYoutube').default
}

export default function ExerciseDetails({ route }) {
  const { exercise } = route.params;
  // TODO: Save private notes of exercise
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (text) => {
    setInputValue(text);
  };

  return (
    <View style={styles.container}>
      <View style={styles.nameView}>
        <Text style={styles.name}>{exercise.name}</Text>
      </View>
      <Divider/>
      <ScrollView >
        {exercise.video && <Youtube videoId={exercise.video}/>}
        <View style={{ flex: 1}}>
          {(exercise.description || exercise.muscle) && <Text variant="titleMedium" style={{ paddingBottom: 5, marginHorizontal: 10}}>Detalhes de execução</Text>}
          {(exercise.description || exercise.muscle) &&  <Divider />}
          {exercise.description && <Text variant="bodyMedium" style={styles.description}>{exercise.description}</Text>}
          {exercise.muscle && <View style={styles.muscle}>
            <Text variant="titleSmall">Grupo muscular primário</Text>
            <Text variant="titleSmall" style={{ fontWeight: 300 }}>{exercise.muscle}</Text>
          </View>}
          <Text variant="titleMedium" style={{ paddingBottom: 5, marginHorizontal: 10, paddingTop: 10}}>Notas privadas</Text>
          <Divider />
          <TextInput
            value={inputValue}
            placeholder="Adicione uma nota..."
            onChangeText={handleInputChange}
            activeUnderlineColor="black"
            underlineColor="black"
            multiline={true}
            dense={true}
            style={styles.input}
          />
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  nameView: {
    backgroundColor: 'white',
    padding: 10,
    paddingBottom: 20,
    alignItems: 'center',
  },
  name: {
    fontSize: 19,
    fontWeight: 600,
    textAlign: 'center'
  },
  muscle: {
    flexDirection: 'row',
    gap: 10,
    marginHorizontal: 10,
    marginVertical: 8
  },
  description: {
    fontWeight: 300, 
    paddingTop: 10, 
    marginHorizontal: 10
  },
  input: {
    fontSize: 14,
    minHeight: 100,
    backgroundColor: '#ebebeb',
    justifyContent:"top",
    paddingTop: 5,
  }
});
