import React, { useState } from 'react'
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth'
import { View, StyleSheet, KeyboardAvoidingView, Image, Platform, Dimensions, StatusBar } from 'react-native'
import { TextInput, ActivityIndicator, Button } from 'react-native-paper'
import { FIREBASE_AUTH } from '../config/firebase';
import alert from '../components/Alert';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const auth = FIREBASE_AUTH;

    const login = async () => {
        setLoading(true);
        try {
            await signInWithEmailAndPassword(auth, email, password);
        } catch (error) {
            console.log(error)
            let errorMessage = ""
            if (error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found' || error.code === 'auth/invalid-login-credentials') {
                errorMessage = "E-mail e/ou password incorretos."
            } else {
                errorMessage = "Ocorreu um erro inesperado. Tente entrar novamente."
            }
            setLoading(false)
            alert('Não foi possível entrar', errorMessage, [
                {
                  text: 'OK',
                  onPress: () => {},
                  style: 'cancel',
                },
            ]);
        }
    }

    const register = async () => {
        return;
        setLoading(true);
        try {
            await createUserWithEmailAndPassword(auth, email, password);
        } catch (error) {
            alert(error.message)
        } finally {
            setLoading(false)
        }
    }

    return (
        <View style={styles.container}>
            <Image
                source={require('../../assets/logo.png')}
                style={{ width: "50%", height: "35%" }}
                resizeMode="contain"
            />
            <KeyboardAvoidingView style={styles.keyboardview} behavior={Platform.OS === "ios" ? "padding" : undefined}>
                <View style={styles.form}>
                    <TextInput
                        value={email}
                        style={styles.input}
                        placeholder="E-mail"
                        onChangeText={(text) => setEmail(text)}
                        autoCapitalize='none'
                        keyboardType="email-address"
                        activeUnderlineColor="black"
                        underlineColor="black"
                        outlineStyle={{ borderRadius: 15 }}
                    />
                    <TextInput
                        value={password}
                        style={styles.input}
                        activeUnderlineColor="black"
                        underlineColor="black"
                        placeholder='Palavra-passe'
                        autoCapitalize='none'
                        onChangeText={(text) => setPassword(text)}
                        secureTextEntry
                    />
                    <View style={styles.buttons}>
                        {loading ? 
                            (
                                <ActivityIndicator animating={true} />
                            ) : (
                                <>
                                    <Button 
                                        style={{ backgroundColor: 'black', marginHorizontal: '10%' }}
                                        labelStyle={{ color: 'white' }}
                                        mode="contained-tonal" 
                                        onPress={login}
                                    >
                                        Iniciar sessão
                                    </Button>
                                    {/* TODO: reset password instead of creating account */}
                                    <Button 
                                        style={{ marginHorizontal: '10%' }}
                                        onPress={register}
                                    >
                                        Esqueceu-se da palavra-passe?
                                    </Button>
                                </>
                            )
                        }
                    </View>
                </View>
            </KeyboardAvoidingView>
            <StatusBar style="auto" />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        paddingTop: 40
    },
    keyboardview: {
        width: '100%',
        alignItems: 'center',
    },
    form: {
        width: Dimensions.get('window').width > 700 ? '40%' : '80%',
        marginVertical: 50,
        gap: 10,
    },
    input: {
        backgroundColor: '#fff',
    },
    buttons: {
        display: 'flex',
        marginTop: 40,
        gap: 15
    }
  });

export default Login