export const formatClock = (seconds) => {
  if (seconds < 0) return "00:00";

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  if (hours > 0) {
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  } else {
    return `${formattedMinutes}:${formattedSeconds}`;
  }
}

export const formatTime = (seconds) => {
  if (seconds < 0) return "0 min";

  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);
  let formattedTime = "";

  if (hours > 0) {
    formattedTime += hours + " hr ";
  }

  if (minutes > 0) {
    formattedTime += minutes + " min";
  }

  if (formattedTime === "") {
    formattedTime = "0 min";
  }

  return formattedTime;
}

export const isNumeric = (str) => {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && !isNaN(parseFloat(str))
}

export const formatDateToCustomString = (date) => {
  const today = new Date();
  const isToday = isSameDay(date, today);
  const day = isToday ? 'Hoje' : getDayOfWeek(date);
  const formattedDate = isToday ? '' : `${date.getDate()} ${getMonthName(date)}`;
  const time = formatTimeCustom(date);

  if (isToday) return `${day}, ${time}`;
  
  return `${day} ${formattedDate}, ${time}`;
}

function isSameDay(date1, date2) {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
}

function getDayOfWeek(date) {
  const daysOfWeek = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
  return daysOfWeek[date.getDay()];
}

function getMonthName(date) {
  const months = [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ];
  return months[date.getMonth()];
}

function formatTimeCustom(date) {
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
}

export const formatInputDate = (timestamp) => {
  const date = new Date(timestamp);

  // Extract the date and time components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  // Create the formatted date-time string
  const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;

  return formattedDateTime;
}

export const formatDateToPortuguese = (givenDateStr) => {
  const date = new Date(givenDateStr);
  const daysOfWeek = [
    "Domingo",
    "Segunda-feira",
    "Terça-feira",
    "Quarta-feira",
    "Quinta-feira",
    "Sexta-feira",
    "Sábado"
  ];
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
  ];

  const dayOfWeek = daysOfWeek[date.getDay()];
  const day = date.getDate();
  const month = months[date.getMonth()];

  return `${dayOfWeek}, ${day} de ${month}`;
}

export const getTimeDifference = (givenDateStr) => {
  const givenDate = new Date(givenDateStr);
  const currentDate = new Date();
  const timeDifference = currentDate - givenDate;
  
  // Calculate the difference in days, hours, and minutes.
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hoursDifference = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
  const minutesDifference = Math.floor((timeDifference / (1000 * 60)) % 60);
  
  if (daysDifference > 0) {
    return `${daysDifference}d`;
  } else if (hoursDifference > 0) {
    return `${hoursDifference}h`;
  } else if (minutesDifference > 0) {
    return `${minutesDifference}m`;
  } else {
    return "Now";
  }
}

export const formatDateToDDMM = (givenDateStr) => {
  const date = new Date(givenDateStr);
  const day = date.getDate().toString().padStart(2, '0'); // Get the day and ensure it has two digits.
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month (0-based) and ensure it has two digits.

  return `${day}/${month}`;
}