import React, { useEffect, useState } from 'react';
import { View, Dimensions, StyleSheet, ScrollView } from 'react-native';
import { LineChart } from 'react-native-chart-kit';
import { Divider, Text, ActivityIndicator } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import ExerciseHistoryItem from './ExerciseHistoryItem';
import { getExerciseHistory } from '../../../actions/workout';
import { setExerciseHistory } from '../../../reducers/exerciseHistoryReducer';
import { formatDateToDDMM } from '../../../utils/utils';

const ExerciseHistory = ({ route }) => {
  const { exercise } = route.params;
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false)
  const user = useSelector((state) => state.user.user);
  const exerciseHistory = useSelector((state) => state.exerciseHistory.exerciseHistory);
  const [tooltipData, setTooltipData] = useState(null);
  const [chartData, setChartData] = useState({ time: [], data: []})

  useEffect(() => {
    async function fetchExerciseHistoryData() {
      const exHistory = await getExerciseHistory(user.uid, exercise.id);
      dispatch(setExerciseHistory({
        exerciseId: exercise.id,
        history: JSON.parse(JSON.stringify(exHistory))
      }))
      setLoading(false);
    }

    // TODO: Clear redux store after a certain amount of time
    if (!exerciseHistory?.hasOwnProperty(exercise.id) || exerciseHistory[exercise.id]?.length === 0) {
      setLoading(true);
      fetchExerciseHistoryData();
    }
  }, [])

  useEffect(() => {
    const getChartData = () => {
      const weights = []
      const time = []
      for (let i = 0; i < exerciseHistory[exercise.id]?.length; i++) {
        const history = exerciseHistory[exercise.id][i];
        let maxWeight = 0;
        for (const set of history.sets) {
          if (set.weight > maxWeight) {
            maxWeight = set.weight;
          }
        }
        time.push(formatDateToDDMM(history.startTime))
        weights.push(maxWeight)
      }
      return { time , data: weights.reverse()}
    }
    setChartData(getChartData())
  }, [exerciseHistory[exercise.id]])
  

  if (isLoading) {
    return (
      <View style={styles.containerNoWorkouts}>
        <Text>A carregar o histórico do exercício...</Text>
        <ActivityIndicator animating={true} />
      </View>
    )
  }

  if (!exerciseHistory || !exerciseHistory?.hasOwnProperty(exercise.id) || exerciseHistory[exercise.id].length === 0) {
    return (
      <View style={styles.containerNoWorkouts}>
        <Text>Não há histórico para este exercício</Text>
      </View>
    )
  }

  const data = {
    labels: [],
    datasets: [{
      data: chartData.data,
    }]
  }

  const chartConfig = {
    backgroundColor: '#ffffff',
    backgroundGradientFrom: '#ffffff',
    backgroundGradientTo: '#ffffff',
    color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`
  }

  const graphStyle = {
    marginVertical: 0,
    marginVertical: 15,
    marginHorizontal: 16,
    marginBottom: -10, 
    ...chartConfig.style
  }

  const width = Dimensions.get("window").width;
  const height = 200

  const formatYLabel = (value) => {
    return Math.round(value * 10) / 10;
  };

  const handleDataPointClick = (data) => {
    setTooltipData(data);
  };

  return (
    <View style={styles.container}>
      <View style={styles.nameView}>
        <Text style={styles.name}>{exercise.name}</Text>
      </View>
      <Divider/>
      <View style={styles.chart}>
        {chartData.data.length > 0 && <LineChart
          data={data}
          width={width}
          height={height}
          fromZero={true}
          chartConfig={chartConfig}
          bezier
          style={graphStyle}
          formatYLabel={formatYLabel}
          withInnerLines={false}
          onDataPointClick={handleDataPointClick}
        />}
      </View>
      {tooltipData && (
        <View style={[styles.tooltip, { top: tooltipData.y + 45, left: tooltipData.x + 10 }]}>
          <Text style={styles.tooltipText}>{tooltipData.value} kg</Text>
          <Text style={styles.tooltipText}>{chartData.time[tooltipData.index]}</Text>
        </View>
      )}
      <Divider/>
      <ScrollView style={{ backgroundColor: 'white'}}>
        {exerciseHistory[exercise.id].map((history, index) => (
          <ExerciseHistoryItem key={index} history={history}/>
        ))}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerNoWorkouts: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    gap: 30
  },
  nameView: {
    backgroundColor: 'white',
    padding: 10,
    paddingBottom: 20,
    alignItems: 'center',
  },
  tooltip: {
    position: 'absolute', 
    padding: 5,
  },
  tooltipText: {
    fontSize: 13,
    fontWeight: 500,
    paddingVertical: 1
  },
  name: {
    fontSize: 19,
    fontWeight: 600,
    textAlign: 'center'
  },
  chart: {
    backgroundColor: 'white',
    alignItems: 'center',
    paddingBottom: 0
  }
});

export default ExerciseHistory;
