import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, DataTable, TextInput, IconButton, Modal, Portal, Button } from 'react-native-paper';
import { useDispatch } from 'react-redux';
import { updateNotes, updateReps, updateWeight, updateNotesSuperset, updateRepsSuperset, updateWeightSuperset } from '../../../reducers/currentWorkoutReducer'
import { formatClock, isNumeric } from '../../../utils/utils'

const Input = ({ defaultValue, onChangeText, error, style }) => {
    return (
        <TextInput
            defaultValue={defaultValue}
            error={error}
            keyboardType = 'decimal-pad'
            onChangeText={onChangeText}
            style={[styles.input, style]}
            dense={true}
            activeUnderlineColor="black"
            underlineColor="black"
        />
    );
 };

const WorkoutExercise = ({ navigation, exercise, index, superset, superIndex }) => {  
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);

    const showModal = () => setVisible(true);
    const hideModal = () => setVisible(false);
    const [inputModal, setInputModal] = useState(exercise.notes);

    const handleInputModalChange = (text) => {
        setInputModal(text);
    };

    const saveNote = () => {
        if (!superset) {
            dispatch(updateNotes({
                index,
                newNote: inputModal,
            }));
        } else {
            dispatch(updateNotesSuperset({
                index,
                superIndex,
                newNote: inputModal,
            }));
        }
        hideModal()
    }

    const [errors, setErrors] = useState({})

    useEffect(() => {
      const tempErrors = {}
      for (let i = 0; i < exercise.sets.length; i++) {
        tempErrors['repsError' + i] = false;
        tempErrors['weightError' + i] = false;
      }
      setErrors(tempErrors)
    }, [])

    return (
        <View style={styles.container}>
            <Portal>
                <Modal visible={visible} onDismiss={hideModal} contentContainerStyle={styles.modal}>
                    <Text>Notas:</Text>
                    <TextInput
                        defaultValue={inputModal}
                        onChangeText={handleInputModalChange}
                        activeUnderlineColor="black"
                        underlineColor="black"
                        multiline={true}
                        dense={true}
                        style={styles.modalInput}
                    />
                    <Button mode="contained" buttonColor="black" onPress={saveNote}>
                        Guardar
                    </Button>
                </Modal>
            </Portal>
            <View style={styles.exerciseinfo}>
                <View style={[styles.exercise, { flex: 1 }]}>
                    <Text variant="titleMedium" style={{ paddingBottom: 3, fontWeight: superset ? 350 : 500 }}>{exercise.name}</Text>
                </View>
                <View style={styles.exercise}>
                    <IconButton 
                        icon="information-outline"
                        size={18}
                        onPress={() => navigation.navigate('ExerciseDetails', { exercise })} 
                    />
                    <IconButton 
                        icon="file-document"
                        size={18}
                        onPress={showModal} 
                    />
                    <IconButton 
                        icon="chart-bar"
                        size={18}
                        onPress={() => navigation.navigate('ExerciseHistory', { exercise })} 
                    />
                </View>
            </View>
            <DataTable>
                <DataTable.Header>
                    <DataTable.Title style={[{flex: 1.5}, styles.title]}>Série</DataTable.Title>
                    <DataTable.Title style={[{flex: 2}, styles.title]}>Reps</DataTable.Title>
                    <DataTable.Title style={[{flex: 2}, styles.title]}>Descanso</DataTable.Title>
                    <DataTable.Title style={[{flex: 2}, styles.title]}>Carga</DataTable.Title>
                    <DataTable.Title style={[{flex: 2}, styles.title]}>Reps Feitas</DataTable.Title>
                </DataTable.Header>

                {exercise.sets.map((set, i) => (
                    <DataTable.Row key={i} style={[styles.row, { backgroundColor: set.weight.toString() !== '' && set.repsDone.toString() !== '' ? '#f2f2f2' : 'white' }]}>
                        <DataTable.Cell style={{flex: 1.5, opacity: 0.6}}>{i+1}</DataTable.Cell>
                        <DataTable.Cell style={{flex: 2, opacity: 0.6}}>{set.reps}</DataTable.Cell>
                        <DataTable.Cell style={{flex: 2, opacity: 0.6}}>{formatClock(set.rest)}</DataTable.Cell>
                        <DataTable.Cell style={{flex: 2}}>
                            <Input
                                defaultValue={set.weight.toString()}
                                style={{ backgroundColor: set.weight.toString() !== '' && set.repsDone.toString() !== '' ? '#f2f2f2' : 'white' }}
                                error={errors['weightError'+i]}
                                onChangeText={(text) => {
                                    const textTemp = text.replace(/,/g, '.');
                                    if (isNumeric(textTemp) || textTemp === "") {
                                        if(!superset) {
                                            dispatch(updateWeight({
                                                exerciseIndex: index,
                                                setIndex: i,
                                                newWeight:  textTemp === "" ? "" : parseFloat(textTemp),
                                            }));
                                        } else {
                                            dispatch(updateWeightSuperset({
                                                exerciseIndex: index,
                                                superIndex,
                                                setIndex: i,
                                                newWeight:  textTemp === "" ? "" : parseFloat(textTemp),
                                            }));
                                        }
                                        setErrors(prev => ({
                                            ...prev, 
                                            [`weightError${i}`]: false
                                        }))
                                    } else {
                                        setErrors(prev => ({
                                            ...prev, 
                                            [`weightError${i}`]: true
                                        }))
                                    }
                                }}
                            />
                        </DataTable.Cell>
                        <DataTable.Cell style={{flex: 2}}>
                            <Input
                                defaultValue={set.repsDone.toString()}
                                error={errors['repsError'+i]}
                                style={{ backgroundColor: set.weight.toString() !== '' && set.repsDone.toString() !== '' ? '#f2f2f2' : 'white' }}
                                onChangeText={(text) => {
                                    const textTemp = text.replace(/,/g, '.');
                                    if (isNumeric(textTemp) || textTemp === "") {
                                        if(!superset) {
                                            dispatch(updateReps({
                                                exerciseIndex: index,
                                                setIndex: i,
                                                newReps:  textTemp === "" ? "" : parseFloat(textTemp),
                                            }));
                                        } else {
                                            dispatch(updateRepsSuperset({
                                                exerciseIndex: index,
                                                superIndex,
                                                setIndex: i,
                                                newReps:  textTemp === "" ? "" : parseFloat(textTemp),
                                            }));
                                        }
                                        setErrors(prev => ({
                                            ...prev, 
                                            [`repsError${i}`]: false
                                        }))
                                    } else {
                                        setErrors(prev => ({
                                            ...prev, 
                                            [`repsError${i}`]: true
                                        }))
                                    }
                                }}
                            />
                        </DataTable.Cell>
                    </DataTable.Row>
                ))}
            </DataTable>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white'
    },
    input: {
        width: '70%', 
        height: 30,
        paddingHorizontal: 0,
    },
    title: {
        paddingVertical: 5,
    },
    modalInput: {
        fontSize: 14,
        minHeight: 100,
        backgroundColor: '#ebebeb',
        paddingTop: 5
    },
    exerciseinfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    exercise: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginHorizontal: 5
    },
    modal: {
        backgroundColor: 'white', 
        margin: 20, 
        padding: 20,
        gap: 15
    },
    row: {
        minHeight: 25,
        padding: 5
    }
});

export default WorkoutExercise;