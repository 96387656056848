import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { combineReducers } from 'redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import userReducer from './reducers/userReducer'
import workoutPlanReducer from './reducers/workoutPlanReducer'
import currentWorkoutReducer from './reducers/currentWorkoutReducer'
import exerciseHistoryReducer from './reducers/exerciseHistoryReducer'

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  // whitelist: ['user'], // Specify the reducers you want to persist
  // blacklist: [] // Specify the reducers to not persist
};

const rootReducer = combineReducers({
  user: userReducer,
  workoutPlan: workoutPlanReducer,
  exerciseHistory: exerciseHistoryReducer,
  currentWorkout: currentWorkoutReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export default store;