import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import EvaluationMain from '../pages/evaluation/EvaluationMain';
import Navbar from '../components/Navbar';

const Stack = createStackNavigator();

export default function EvaluationRoute() {
  return (
    <Stack.Navigator 
      initialRouteName="EvaluationMain"
      screenOptions={{
        header: ({ navigation }) => (
          <Navbar navigation={navigation} />
        ),
      }}
    >
      <Stack.Screen name="EvaluationMain" component={EvaluationMain} />
    </Stack.Navigator>
  );
}
