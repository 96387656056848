import React, {useState} from 'react';
import { Calendar, LocaleConfig } from 'react-native-calendars';
import { StyleSheet, View, ScrollView } from 'react-native';
import { List, Divider, Card, Avatar, IconButton, Title, Paragraph, Button} from 'react-native-paper';

LocaleConfig.locales['pt'] = {
  monthNames: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ],
  monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
  dayNamesShort: ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom'],
  today: "Hoje"
};

LocaleConfig.defaultLocale = 'pt';

export default function AppointmentMain() {
  const [selected, setSelected] = useState('');

  // TODO: disable some options on the calendar?

  const customTheme = {
    calendarBackground: 'white',
    selectedDayBackgroundColor: 'black',
    selectedDayTextColor: 'white',
    todayTextColor: 'black',
    dayTextColor: 'black',
    textSectionTitleColor: 'black',
    textSectionTitleDisabledColor: 'grey',
    arrowColor: 'black',
    monthTextColor: 'black',
    indicatorColor: 'black',
    dotColor: 'black',
  };

  return (
    <>
      <Calendar
        theme={customTheme}
        onDayPress={day => {
          setSelected(day.dateString);
        }}
        markedDates={{
          [selected]: {selected: true, disableTouchEvent: true},
          // '2023-10-01': {selected: true, marked: true, selectedColor: 'blue'},
          '2023-10-02': {marked: true, selected: '2023-10-02' === selected, disableTouchEvent: '2023-10-02' === selected},
          '2023-10-03': {marked: true, selected: '2023-10-03' === selected, disableTouchEvent: '2023-10-03' === selected}
        }}
      />
      <Divider/>
      <ScrollView>
        <View style={styles.container}>
          <List.Section>
            <Card style={styles.item}>
              <Card.Title
                title="Treino PT"
                subtitle="12:00-13:00"
                left={(props) => <Avatar.Icon {...props} icon="dumbbell" />}
                right={(props) => <IconButton {...props} icon="dots-vertical" onPress={() => {}} />}
              />
            </Card>
            <Card style={styles.item}>
              <Card.Title
                title="Consulta de Nutrição"
                subtitle="15:00-16:00"
                left={(props) => <Avatar.Icon {...props} icon="nutrition" />}
                right={(props) => <IconButton {...props} icon="dots-vertical" onPress={() => {}} />}
              />
            </Card>
            <Card style={styles.item}>
              <Card.Title
                title="Fisioterapia"
                subtitle="18:00-19:00"
                left={(props) => <Avatar.Icon {...props} icon="doctor" />}
                right={(props) => <IconButton {...props} icon="dots-vertical" onPress={() => {}} />}
              />
            </Card>
            <Card style={styles.item}>
              <Card.Title
                title="Treino PT"
                subtitle="20:00-21:00"
                left={(props) => <Avatar.Icon {...props} icon="dumbbell" />}
                right={(props) => <IconButton {...props} icon="dots-vertical" onPress={() => {}} />}
              />
            </Card>
            {/* Nova marcação só possível para no futuro */}
            <Button 
              icon="plus" 
              style={{ backgroundColor: 'black' }}
              labelStyle={{ color: 'white' }}
              mode="contained-tonal" 
              onPress={() => console.log('nova marcação')}
            >
              Nova Marcação
            </Button>
          </List.Section>
        </View>
      </ScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 15,
  },
  item: {
    marginBottom: 15,
    backgroundColor: 'white'
  }
});
