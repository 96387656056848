import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Divider, DataTable, Text } from 'react-native-paper';
import { getTimeDifference, formatDateToPortuguese } from '../../../utils/utils';

export default function ExerciseHistoryItem({ history }) {
    return (
        <View style={styles.container}>
            <Divider/>
            <View style={styles.date}>
                <Text variant="bodyMedium" style={{ fontWeight: 400 }}>{formatDateToPortuguese(history.startTime)}</Text>
                <Text variant="bodyMedium" style={{ fontWeight: 200 }}>{getTimeDifference(history.startTime)}</Text>
            </View>
            <Divider/>
            <View style={styles.tableView}>
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title style={[{flex: 1.5}, styles.title]}>Série</DataTable.Title>
                        <DataTable.Title style={[{flex: 2}, styles.title]}>Reps</DataTable.Title>
                        <DataTable.Title style={[{flex: 2}, styles.title]}>Carga</DataTable.Title>
                    </DataTable.Header>

                    {history.sets.map((set, index) => (
                        <DataTable.Row key={index} style={styles.row}>
                            <DataTable.Cell style={{flex: 1.5}}>{index+1}</DataTable.Cell>
                            <DataTable.Cell style={{flex: 2}}>{set.repsDone}</DataTable.Cell>
                            <DataTable.Cell style={{flex: 2}}>{set.weight}</DataTable.Cell>
                        </DataTable.Row>
                    ))}
                </DataTable>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
      marginTop: 15,
    },
    date: {
        flexDirection: 'row',
        paddingHorizontal: 10,
        paddingVertical: 3,
        justifyContent: 'space-between',
    },
    tableView: {
        marginHorizontal: 30,
        paddingBottom: 20
    },
    title: {
        paddingVertical: 0,
        paddingTop: 5
    },
    row: {
        minHeight: 25,
        padding: 0
    }
});