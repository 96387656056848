import React from 'react';
import { View } from 'react-native';
import YoutubePlayer from 'react-native-youtube-iframe';

const MobileYoutube = ({ videoId }) => {
    return (
      <View>
        <YoutubePlayer
            height={230}
            videoId={videoId}
        />
      </View>
    );
};

export default MobileYoutube;