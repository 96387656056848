import React, { useState, useEffect } from 'react';
import { View, StyleSheet, ScrollView, Platform } from 'react-native';
import { Text, ProgressBar, Chip, Divider, Button, DataTable, TouchableRipple } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import DateTimePickerModal from "react-native-modal-datetime-picker";
import WorkoutExercise from './exercise/WorkoutExercise';
import { formatClock, formatDateToCustomString, formatInputDate } from '../../utils/utils';
import { updateStartTime } from '../../reducers/currentWorkoutReducer'
import alert from '../../components/Alert'

export default function CurrentWorkoutPage({ navigation }) {
    const currentWorkout = useSelector((state) => state.currentWorkout.currentWorkout);
    const dispatch = useDispatch();
    const [restClock, setRestClock] = useState(null)
    const [workoutClock, setWorkoutClock] = useState(null)
    const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        updateRestClock()
    }, [currentWorkout?.restStart, currentTime])

    useEffect(() => {
        updateTimeClock()
    }, [currentWorkout?.startTime, currentTime])

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);
    
        return () => {
            clearInterval(interval);
        };
    }, []);

    const updateRestClock = () => {
        if (currentWorkout?.restStart && currentWorkout?.restStart !== "") {
            setRestClock(Math.round((new Date() - new Date(currentWorkout?.restStart)) / 1000))
        }
    }

    const updateTimeClock = () => {
        if (currentWorkout?.startTime && currentWorkout?.startTime !== "") {
            setWorkoutClock(Math.round((new Date() - new Date(currentWorkout?.startTime)) / 1000))
        }
    }

    const showDatePicker = () => {
        setDatePickerVisibility(true);
    };

    const hideDatePicker = () => {
        setDatePickerVisibility(false);
    };

    const handleConfirm = (date) => {
        hideDatePicker();
        dispatch(updateStartTime(JSON.parse(JSON.stringify(date))))
    };

    const finishWorkout = () => {
        const navigateToFinishPage = () => navigation.navigate("FinishWorkoutPage", { endTime: new Date().getTime() })
        if (currentWorkout.setsDone < currentWorkout.totalSets) {
            alert('Treino incompleto', 'Ainda não registou todos os exercícios. Deseja continuar?', [
                {
                  text: 'Voltar',
                  onPress: () => {},
                  style: 'cancel',
                },
                {text: 'Continuar', onPress: navigateToFinishPage},
            ]);
        } else {
            navigateToFinishPage()
        }
    }

    if (!currentWorkout) {
        return (
            <View style={styles.containerNoWorkouts}>
              <Text>Não há nenhum treino a ser executado</Text>
            </View>
        )
    }

    return (
        <View style={{flex: 1}}>  
            <View>
                <ProgressBar progress={currentWorkout.setsDone/currentWorkout.totalSets} color='black' style={{ backgroundColor: '#ebebeb' }} />
            </View>
            <View style={styles.container}>
                <View style={{backgroundColor: 'white'}}>
                    <Text style={styles.workoutTitle}>{currentWorkout.name}</Text>
                    <View style={styles.timers}>
                        <Chip style={styles.chip} icon="timer" mode="outlined">{formatClock(workoutClock)}</Chip>
                        <Chip style={styles.chip} selectedColor={(currentWorkout?.restDuration && restClock !== "" && (currentWorkout?.restDuration - restClock - 5 < 0)) ? 'red' : null} icon="timer-sand" mode="outlined">{formatClock(restClock)}</Chip>
                    </View>
                </View>
                <Divider />
                <ScrollView>
                    <Divider />
                    <DataTable>
                        <DataTable.Row style={{ padding: 0, minHeight: 40 }}>
                            <DataTable.Cell><Text variant="titleSmall" style={{ paddingLeft: Platform.OS === "web" ? 15 : 0 }}>Hora de Início</Text></DataTable.Cell>
                            <DataTable.Cell style={{flex: 2}}>
                                {Platform.OS !== "web" && <TouchableRipple onPress={showDatePicker} style={{paddingTop: Platform.OS === "ios" ? 5: 0}}>
                                    <Text>{formatDateToCustomString(new Date(currentWorkout.startTime))}</Text>
                                </TouchableRipple>}
                                {Platform.OS === "web" && <input type="datetime-local"
                                    value={formatInputDate(new Date(currentWorkout.startTime))}
                                    onChange={e => dispatch(updateStartTime(JSON.parse(JSON.stringify(new Date(e.target.value)))))} />
                                }
                            </DataTable.Cell>
                        </DataTable.Row>
                    </DataTable>
                    <DateTimePickerModal
                        isVisible={isDatePickerVisible}
                        mode="datetime"
                        date={new Date(currentWorkout.startTime)}
                        themeVariant={'light'} // TODO: Change for dark mode
                        buttonTextColorIOS="black"
                        onConfirm={handleConfirm}
                        onCancel={hideDatePicker}
                    />
                    <Divider />
                    <View style={styles.list}>
                        {currentWorkout.exercises.map((exercise, index) => {
                            if (!exercise.superset) return (<WorkoutExercise key={exercise.id} index={index} navigation={navigation} exercise={exercise}/>)
                            else {
                                return (
                                    <View key={index} style={styles.superset}>
                                        <Text variant="titleMedium" style={{ paddingBottom: 5 }}>Super Série</Text>
                                        {exercise.superset.map((superEx, superIndex) => (
                                            <WorkoutExercise key={superEx.id} index={index} superIndex={superIndex} navigation={navigation} exercise={superEx} superset/>
                                        ))}
                                    </View>
                                )
                            }
                        })}
                    </View>
                </ScrollView>
                <Divider />
                <View style={styles.button}>
                    <Button 
                        icon="check" 
                        style={{ backgroundColor: 'black' }}
                        labelStyle={{ color: 'white' }}
                        mode="contained-tonal" 
                        onPress={finishWorkout}
                        >
                        Finalizar treino
                    </Button>
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: 0,
        backgroundColor: 'white'
    },
    containerNoWorkouts: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    workoutTitle: {
        textAlign: 'center',
        marginTop: 12,
        fontSize: 20,
        fontWeight: 600
    },
    timers: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: 16,
        marginVertical: 10
    },
    chip: {
        borderWidth: 0
    },
    list: {
        margin: 10,
        marginVertical: 20,
        gap: 25
    },
    button: {
        marginHorizontal: 15,
        marginVertical: 10,
    },
    startTime: {
        fontWeight: 700
    },
    superset: {
        borderLeftWidth: 3,
        borderLeftColor: 'black',
        paddingHorizontal: 10,
        marginVertical: 10,
    } 
});