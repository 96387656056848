import React from 'react';
import { Appbar, Menu } from 'react-native-paper';
import { Image, Platform, StyleSheet} from 'react-native';
import { useRoute } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import { setCurrentWorkout } from '../reducers/currentWorkoutReducer'
import alert from './Alert';

const Navbar = ({ navigation }) => {  
  const route = useRoute();
  const dispatch = useDispatch();
  const MORE_ICON = Platform.OS === 'ios' ? 'dots-horizontal' : 'dots-vertical';

  const [visible, setVisible] = React.useState(false);

  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);
  
  const canGoBack = navigation.canGoBack() && route.name.indexOf('Main') === -1
  const runningWorkoutPage = route.name === "CurrentWorkoutPage" || route.name === "FinishWorkoutPage";

  const deleteCurrentWorkoutAlert = () => {
    alert('Apagar Treino', 'Tem a certeza que pretende apagar o treino em curso?', [
      {
        text: 'Cancelar',
        onPress: () => {},
        style: 'cancel',
      },
      {
        text: 'Apagar', 
        onPress: deleteCurrentWorkout,
        style: 'destructive'
      },
    ]);  
  }

  const deleteCurrentWorkout = () => {
    closeMenu()
    dispatch(setCurrentWorkout(null))
    navigation.navigate('WorkoutMain')
  }

  return (
    <Appbar.Header>
        {canGoBack && <Appbar.BackAction
          onPress={() => navigation.goBack()}
        />}
      <Appbar.Content 
          title={<Image
              source={require('../../assets/icon.png')}
              style={{ width: 40, height: 40 }}
          />}
          style={{ alignItems: 'center', justifyContent: 'center', flex: 1, marginLeft: 0 }}
      />
      {canGoBack && !runningWorkoutPage && Platform.OS !== "ios" && <Appbar.Action />}
      {runningWorkoutPage && <Menu
        contentStyle={styles.menu}
        visible={visible}
        onDismiss={closeMenu}
        anchor={<Appbar.Action icon={MORE_ICON} onPress={openMenu} />}>
        <Menu.Item onPress={deleteCurrentWorkoutAlert} leadingIcon="delete" title="Apagar" />
      </Menu>}
    </Appbar.Header>
  );
};

const styles = StyleSheet.create({
  menu: {
    backgroundColor: 'white'
  },
});

export default Navbar;