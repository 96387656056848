import { createSlice } from '@reduxjs/toolkit'

export const workoutPlanSlice = createSlice({
  name: 'workoutPlan',
  initialState: {
    workoutPlan: null,
  },
  reducers: {
    setWorkoutPlan: (state, action) => {
      state.workoutPlan = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setWorkoutPlan } = workoutPlanSlice.actions

export default workoutPlanSlice.reducer