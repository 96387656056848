import { createSlice } from '@reduxjs/toolkit'

export const currentWorkoutSlice = createSlice({
  name: 'currentWorkout',
  initialState: {
    currentWorkout: null,
  },
  reducers: {
    setCurrentWorkout: (state, action) => {
      const workout = action.payload

      if (!workout) {
        state.currentWorkout = null;
        return;
      }

      let totalSets = 0
      const exercises = []
      for (let i = 0; i < workout.exercises.length; i++) {
        const exercise = workout.exercises[i];

        if (!exercise.superset) {
          const sets = []
          for (let n = 0; n < exercise.sets.length; n++) {
            totalSets++;
            const set = exercise.sets[n];
            sets.push({
              ...set,
              weight: "",
              repsDone: ""
            })
          }
          exercises.push({
            ...exercise,
            sets: sets,
            notes: ""
          })
        } else {
          const superset = []
          for (let n = 0; n < exercise.superset.length; n++) {
            const superEx = exercise.superset[n];
            const sets = []
            for (let n = 0; n < superEx.sets.length; n++) {
              totalSets++;
              const set = superEx.sets[n];
              sets.push({
                ...set,
                weight: "",
                repsDone: ""
              })
            }
            superset.push({
              ...superEx,
              sets: sets,
              notes: ""
            })
          }
          exercises.push({ superset })
        }
      }

      const tempWorkout = {
        ...workout,
        exercises: exercises,
        startTime: new Date().toISOString(),
        restStart: "",
        restDuration: 0,
        setsDone: 0,
        totalSets: totalSets
      }

      state.currentWorkout = tempWorkout;
    },
    updateNotes: (state, action) => {
      const { index, newNote } = action.payload;
      state.currentWorkout.exercises[index].notes = newNote;
    },
    updateNotesSuperset: (state, action) => {
      const { index, superIndex, newNote } = action.payload;
      state.currentWorkout.exercises[index].superset[superIndex].notes = newNote;
    },
    updateReps: (state, action) => {
      const { exerciseIndex, setIndex, newReps } = action.payload;

      // Update sets done
      if (state.currentWorkout.exercises[exerciseIndex].sets[setIndex].weight !== "") {
        if (newReps !== "" && state.currentWorkout.exercises[exerciseIndex].sets[setIndex].repsDone === "") {
          state.currentWorkout.setsDone++;
          state.currentWorkout.restStart = new Date().toISOString()
          state.currentWorkout.restDuration = state.currentWorkout.exercises[exerciseIndex].sets[setIndex].rest
        }
        else if (newReps === "") state.currentWorkout.setsDone--;
      }

      state.currentWorkout.exercises[exerciseIndex].sets[setIndex].repsDone = newReps;
    },
    updateRepsSuperset: (state, action) => {
      const { exerciseIndex, superIndex, setIndex, newReps } = action.payload;

      // Update sets done
      if (state.currentWorkout.exercises[exerciseIndex].superset[superIndex].sets[setIndex].weight !== "") {
        if (newReps !== "" && state.currentWorkout.exercises[exerciseIndex].superset[superIndex].sets[setIndex].repsDone === "") {
          state.currentWorkout.setsDone++;
          state.currentWorkout.restStart = new Date().toISOString()
          state.currentWorkout.restDuration = state.currentWorkout.exercises[exerciseIndex].superset[superIndex].sets[setIndex].rest
        }
        else if (newReps === "") state.currentWorkout.setsDone--;
      }

      state.currentWorkout.exercises[exerciseIndex].superset[superIndex].sets[setIndex].repsDone = newReps;
    },
    updateWeight: (state, action) => {
      const { exerciseIndex, setIndex, newWeight } = action.payload;

      // Update sets done
      if (state.currentWorkout.exercises[exerciseIndex].sets[setIndex].repsDone !== "") {
        if (newWeight !== "" && state.currentWorkout.exercises[exerciseIndex].sets[setIndex].weight === "") {
          state.currentWorkout.setsDone++;
          state.currentWorkout.restStart = new Date().toISOString()
          state.currentWorkout.restDuration = state.currentWorkout.exercises[exerciseIndex].sets[setIndex].rest
        }
        else if (newWeight === "") state.currentWorkout.setsDone--;
      }

      state.currentWorkout.exercises[exerciseIndex].sets[setIndex].weight = newWeight;
    },
    updateWeightSuperset: (state, action) => {
      const { exerciseIndex, superIndex, setIndex, newWeight } = action.payload;

      // Update sets done
      if (state.currentWorkout.exercises[exerciseIndex].superset[superIndex].sets[setIndex].repsDone !== "") {
        if (newWeight !== "" && state.currentWorkout.exercises[exerciseIndex].superset[superIndex].sets[setIndex].weight === "") {
          state.currentWorkout.setsDone++;
          state.currentWorkout.restStart = new Date().toISOString()
          state.currentWorkout.restDuration = state.currentWorkout.exercises[exerciseIndex].superset[superIndex].sets[setIndex].rest
        }
        else if (newWeight === "") state.currentWorkout.setsDone--;
      }

      state.currentWorkout.exercises[exerciseIndex].superset[superIndex].sets[setIndex].weight = newWeight;
    },
    updateStartTime: (state, action) => {
      state.currentWorkout.startTime = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setCurrentWorkout, updateNotes, updateReps, updateWeight, updateStartTime, updateNotesSuperset, updateRepsSuperset, updateWeightSuperset } = currentWorkoutSlice.actions

export default currentWorkoutSlice.reducer