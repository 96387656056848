import { collection, getDocs, getDoc, limit, doc, query, where, setDoc, Timestamp, orderBy } from "firebase/firestore";
import { FIRESTORE_DB } from '../config/firebase';

const getExercise = async (uid) => {
    if (!uid) return null;
    
    const documentRef = doc(FIRESTORE_DB, "exercises", uid);

    try {
    const documentSnapshot = await getDoc(documentRef);
    if (documentSnapshot.exists()) {
        return documentSnapshot.data()
    } else {
        return null
    }
    } catch (error) {
        return null
    }
}

export const getWorkoutPlan = async (userId) => {
    console.log('getWorkoutPlan')

    if (!userId) return null

    try {
        const workoutPlansRef = collection(FIRESTORE_DB, "users", userId, "workoutPlans")
        const currentDate = Timestamp.fromDate(new Date());

        const q = query(workoutPlansRef, where("endDate", ">=", currentDate));
        const querySnapshot = await getDocs(q);
        
        const workoutPlans = []
        querySnapshot.forEach((doc) => {
          const data = doc.data()
          if (data.startDate <= currentDate) {
            workoutPlans.push({
                ...data,
                id: doc.id,
                startDate: data.startDate.seconds,
                endDate: data.endDate.seconds
            })
          }
        });

        if (workoutPlans.length === 0) return null

        const workoutPlan = (workoutPlans.sort((a, b) => a.startDate - b.startDate))[0]
        const workouts = []
        for (let i = 0; i < workoutPlan.workouts.length; i++) {
            const wk = workoutPlan.workouts[i];
            const exercises = []
            for (let n = 0; n < wk.exercises.length; n++) {
                const ex = wk.exercises[n];
                if (!ex.superset) {
                    const exerciseData = await getExercise(ex.id)
                    exercises.push({...ex, ...exerciseData})
                } else {
                    const superset = []
                    for (let m = 0; m < ex.superset.length; m++) {
                        const superEx = ex.superset[m];
                        const exerciseData = await getExercise(superEx.id)
                        superset.push({...superEx, ...exerciseData})
                    }
                    exercises.push({ superset })
                }
            }
            workouts.push({...wk, exercises: exercises})
        }

        return {...workoutPlan, workouts: workouts }
    } catch (error) {
        console.log(error)
        return null
    }
}

export const saveWorkout = async (userId, currentWorkout, workoutPlan, duration, notes) => {
    console.log('saveWorkout')
    try {
        const startTime = Timestamp.fromDate(new Date(currentWorkout.startTime));
        const workoutHistory = {
            ...currentWorkout,
            workoutPlanId: workoutPlan.id,
            workoutIndex: currentWorkout.index,
            duration: duration,
            notes: notes,
            startTime
        }

        if (workoutHistory.notes === "") delete workoutHistory.notes;

        delete workoutHistory.index;
        delete workoutHistory.restStart;
        delete workoutHistory.restDuration;

        const workoutHistorysRef = collection(FIRESTORE_DB, "users", userId, "workoutHistory");
        
        const newWorkoutHistoryDocRef = doc(workoutHistorysRef);
        await setDoc(newWorkoutHistoryDocRef, workoutHistory);

        for (let i = 0; i < workoutHistory.exercises.length; i++) {
            const exercise = workoutHistory.exercises[i];

            if (!exercise.superset) {
                await saveExercise(userId, newWorkoutHistoryDocRef.id, exercise.id, startTime, exercise.sets)
            } else {
                for (let n = 0; n < exercise.superset.length; n++) {
                    const superEx = exercise.superset[n];
                    await saveExercise(userId, newWorkoutHistoryDocRef.id, superEx.id, startTime, superEx.sets)
                }
            }
        }
        console.log('saveWorkout: finished')
        return true
    } catch (error) {
        console.error('saveWorkout: ', error)
        return false
    }
}

const saveExercise = async (userId, workoutHistoryId, exerciseId, startTime, sets) => {
    try {
        const exerciseHistorysRef = collection(FIRESTORE_DB, "users", userId, "exerciseHistory");

        const tempSets = []
        for (let n = 0; n < sets.length; n++) {
            const set = sets[n];
            if (set.repsDone !== "" && set.weight !== "") tempSets.push(set)
        }

        if (tempSets.length > 0) {
            await setDoc(doc(exerciseHistorysRef), {
                id: exerciseId,
                sets: tempSets,
                workoutHistoryId: workoutHistoryId,
                startTime
            });
        }

    } catch (error) {
        console.error('saveExercise: ', error)
        throw error;
    }
}

export const getExerciseHistory = async (userId, exerciseId) => {
    console.log('getExerciseHistory')

    try {
        const exerciseHistorysRef = collection(FIRESTORE_DB, "users", userId, "exerciseHistory");
        const q = query(exerciseHistorysRef, where("id", "==", exerciseId), orderBy("startTime", "desc"), limit(20));
        const querySnapshot = await getDocs(q);
        
        const exerciseHistory = []
        querySnapshot.forEach((doc) => {
            const data = doc.data()
            exerciseHistory.push({
                ...data,
                startTime: new Date(data.startTime.seconds*1000).toISOString(),
                id: doc.id,
            })
        });

        return exerciseHistory;
    } catch (error) {
        console.error(error)
        return []
    }
}

export const deleteWorkout = async () => {
    // TODO: Delete from workoutHistory and exerciseHistory

    // const workoutHistorysRef = collection(FIRESTORE_DB, "users", userId, "workoutHistory");
    // const exerciseHistorysRef = collection(FIRESTORE_DB, "users", userId, "exerciseHistory");
}