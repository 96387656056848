import React, { useState } from 'react';
import { StyleSheet, ScrollView, View } from 'react-native';
import { TextInput, Button, List, Divider, IconButton, Text } from 'react-native-paper';

export default function FoodMain() {
  return (
    <View style={styles.container}>
      <Text>Nenhum plano de alimentação disponível</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

// export default function FoodMain() {
//   const [foodItem, setFoodItem] = useState('');
//   const [dietItems, setDietItems] = useState([]);

//   const addFoodItem = () => {
//     if (foodItem.trim() !== '') {
//       setDietItems([...dietItems, foodItem]);
//       setFoodItem('');
//     }
//   };

//   const removeFoodItem = (itemIndex) => {
//     const updatedDietItems = [...dietItems];
//     updatedDietItems.splice(itemIndex, 1);
//     setDietItems(updatedDietItems);
//   };

//   return (
//     <ScrollView contentContainerStyle={styles.container}>
//       <TextInput
//         label="Add Food Item"
//         value={foodItem}
//         onChangeText={(text) => setFoodItem(text)}
//         style={styles.textInput}
//       />
//       <Button mode="contained" onPress={addFoodItem} style={styles.addButton}>
//         Add
//       </Button>

//       <Divider />

//       <List.Section>
//         <List.Subheader>Diet Plan</List.Subheader>
//         {dietItems.map((item, index) => (
//           <List.Item
//             key={index}
//             title={item}
//             right={() => (
//               <IconButton
//                 icon="delete"
//                 color="red"
//                 size={20}
//                 onPress={() => removeFoodItem(index)}
//               />
//             )}
//           />
//         ))}
//       </List.Section>
//     </ScrollView>
//   );
// }

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     padding: 16,
//   },
//   textInput: {
//     marginBottom: 10,
//   },
//   addButton: {
//     alignSelf: 'flex-end',
//   },
// });

