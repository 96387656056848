import React from 'react';
import { View, StyleSheet, ScrollView, Alert } from 'react-native';
import { Text, Divider, Button } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import WorkoutExerciseInfo from './exercise/WorkoutExerciseInfo';
import { setCurrentWorkout } from '../../reducers/currentWorkoutReducer'
import alert from '../../components/Alert';

export default function WorkoutPage({ route, navigation }) {
    const { workout, index } = route.params;
    const dispatch = useDispatch();
    const currentWorkout = useSelector((state) => state.currentWorkout.currentWorkout);

    const onStartWorkoutPress = () => {

        const starWorkout = () => {
            dispatch(setCurrentWorkout(JSON.parse(JSON.stringify({...workout, index}))))
            navigation.navigate('CurrentWorkoutPage')
        }

        if (currentWorkout) {
            alert('Treino já em curso', 'Pretende descartar e começar um novo treino ou voltar para o treino em curso?', [
                {
                  text: 'Voltar',
                  onPress: () => navigation.navigate('CurrentWorkoutPage'),
                  style: 'cancel',
                },
                {text: 'Começar novo', onPress: starWorkout},
            ]);
        } else {
            starWorkout()
        }
    }

    if (!workout.exercises || workout.exercises.length === 0) {
        return (
            <View style={styles.containerNoWorkouts}>
              <Text>Não há nenhum exercício neste plano de treino</Text>
            </View>
        )
    }

    return (
        <View style={{flex: 1}}> 
            <View style={styles.container}>
                <Text style={styles.workoutTitle}>{workout.name}</Text>
                <View style={styles.button}>
                    <Button 
                        icon="play" 
                        style={{ backgroundColor: 'black' }}
                        labelStyle={{ color: 'white' }}
                        mode="contained-tonal" 
                        onPress={onStartWorkoutPress}
                        >
                        Começar treino
                    </Button>
                </View>
                <Divider />
                <ScrollView>
                    <View style={styles.list}>
                        {workout.exercises.map((exercise, index) => {
                            if (!exercise.superset) return (<WorkoutExerciseInfo key={exercise.id} navigation={navigation} exercise={exercise}/>)
                            else {
                                return (
                                    <View key={index} style={styles.superset}>
                                        <Text variant="titleMedium" style={{ paddingBottom: 5 }}>Super Série</Text>
                                        {exercise.superset.map((superEx) => (
                                            <WorkoutExerciseInfo key={superEx.id} navigation={navigation} exercise={superEx} superset/>
                                        ))}
                                    </View>
                                )
                            }
                        })}
                    </View>
                </ScrollView>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: 0,
        backgroundColor: 'white'
    },
    dialog: {
        backgroundColor: 'white',
        borderRadius: 0
    },
    workoutTitle: {
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 600
    },
    button: {
        marginHorizontal: 15,
        marginVertical: 15
    },
    containerNoWorkouts: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        gap: 30
    },
    workoutTitle: {
        textAlign: 'center',
        marginTop: 12,
        fontSize: 20,
        fontWeight: 600
    },
    timers: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: 16,
        marginVertical: 10
    },
    chip: {
        borderWidth: 0
    },
    list: {
        margin: 10,
        marginBottom: 20,
        gap: 25
    },
    superset: {
        borderLeftWidth: 3,
        borderLeftColor: 'black',
        paddingHorizontal: 10,
        marginVertical: 10,
    } 
  });