import { collection, getDocs, getDoc, doc, query, where } from "firebase/firestore";
import { FIRESTORE_DB } from '../config/firebase';

export const getAllUsers = async () => {
    const usersCol = collection(FIRESTORE_DB, 'users');

    try {
        const usersSnapshot = await getDocs(usersCol);
        const users = [];

        usersSnapshot.forEach((doc) => {
            users.push({ id: doc.id, data: doc.data() });
        });

        return users;
    } catch (error) {
        console.error("Error getting users:", error);
        return [];
    }
}

export const getUserData = async (uid) => {
    if (!uid) return null;
    
    console.log("getUserData")
    const documentRef = doc(FIRESTORE_DB, "users", uid);

    try {
    const documentSnapshot = await getDoc(documentRef);
    if (documentSnapshot.exists()) {
        return documentSnapshot.data()
    } else {
        return null
    }
    } catch (error) {
        return null
    }
}
