import { StyleSheet, View, ScrollView } from 'react-native';
import { Avatar, Button, Card, List, Divider, Title, Paragraph } from 'react-native-paper';
import { useDispatch } from 'react-redux';
import { FIREBASE_AUTH } from '../../config/firebase';
import { setExerciseHistory } from '../../reducers/exerciseHistoryReducer';
import { setCurrentWorkout } from '../../reducers/currentWorkoutReducer';
import { setWorkoutPlan } from '../../reducers/workoutPlanReducer';

export default function ProfileMain({ navigation }) {
  const dispatch = useDispatch();

  const logout = () => {
    FIREBASE_AUTH.signOut()
  }

  const update = () => {
    dispatch(setWorkoutPlan(null))
    dispatch(setCurrentWorkout(null))
    dispatch(setExerciseHistory(null))
    navigation.navigate('WorkoutMain')
  }

  return (
    <ScrollView>
      <View style={styles.container}>
        {/* <Card>
          <Card.Content>
            <Avatar.Image
              source={{ uri: 'https://example.com/your-profile-image.jpg' }}
              size={100}
            />
            <Title>Your Name</Title>
            <Paragraph>Your Bio</Paragraph>
          </Card.Content>
          <Card.Actions>
            <Button>Edit Profile</Button>
          </Card.Actions>
        </Card>

        <Divider /> */}
        <Button 
          onPress={update}
          style={{ backgroundColor: 'white', marginBottom: 20, borderWidth: .5, borderColor: '#d4d4d4' }}
          labelStyle={{ color: 'black' }}
          mode="contained-tonal" 
        >
          Atualizar aplicação
        </Button>
        <Button 
          onPress={logout}
          style={{ backgroundColor: 'black' }}
          labelStyle={{ color: 'white' }}
          mode="contained-tonal" 
        >
          Terminar sessão
        </Button>

        {/* <List.Section>
          <List.Item
            title="Posts"
            style={styles.item}
            description="10"
            left={() => <List.Icon icon="format-list-bulleted" />}
            onPress={() => console.log("Pressed")}
          />
          <List.Item
            title="Followers"
            style={styles.item}
            description="1000"
            left={() => <List.Icon icon="account-group" />}
            onPress={() => console.log("Pressed")}
          />
          <List.Item
            title="Following"
            style={styles.item}
            description="500"
            left={() => <List.Icon icon="account-group-outline" />}
            onPress={() => console.log("Pressed")}
          />
          <List.Item
            title="Another"
            style={styles.item}
            description="500"
            left={() => <List.Icon icon="account-group-outline" />}
            onPress={() => console.log("Pressed")}
          />
        </List.Section>
        <Divider/>
        <List.Section>
          <List.Subheader>Configurações</List.Subheader>
          <List.Item 
            title="First Item" 
            left={() => <List.Icon icon="folder" />} 
            style={styles.item}
          />
          <List.Item
            title="Second Item"
            left={() => <List.Icon icon="folder" />}
            style={styles.item}
          />
        </List.Section> */}
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 16,
  },
  item: {
    padding: 10
  }
});
