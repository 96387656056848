import React, { useState, useEffect } from 'react';
import { registerRootComponent } from 'expo'; // Uncomment for web
import { Platform, Image, Dimensions } from 'react-native';
// import { AppRegistry } from 'react-native'; // Comment for web
import { PaperProvider, DefaultTheme, View, Text, ActivityIndicator } from 'react-native-paper';
import { createStackNavigator } from '@react-navigation/stack';
import { NavigationContainer } from '@react-navigation/native';
import { Provider as StoreProvider, useDispatch, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { onAuthStateChanged } from 'firebase/auth'
import * as SplashScreen from 'expo-splash-screen';
// import { name as appName } from './app.json'; // Comment for web
import store, { persistor } from './src/store';
import Home from './src/Home';
import Login from './src/pages/Login';
import { FIREBASE_AUTH } from './src/config/firebase';
import { setUser } from './src/reducers/userReducer';
import { getUserData } from './src/actions/user';
import { setExerciseHistory } from './src/reducers/exerciseHistoryReducer';
import { setCurrentWorkout } from './src/reducers/currentWorkoutReducer';
import { setWorkoutPlan } from './src/reducers/workoutPlanReducer';

SplashScreen.preventAutoHideAsync();

const customTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: 'black',
    secondary: 'white',
    background: 'white',
    surface: 'white',
    primaryContainer: 'black',
    secondaryContainer: '#ebebeb'
  },
};

const Stack = createStackNavigator();

const Main = () => {
  const [appIsReady, setAppIsReady] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    onAuthStateChanged(FIREBASE_AUTH, async (newUser) => {
      if (newUser) {
        const userData = await getUserData(newUser.uid);
        if (userData) newUser = {...newUser, data: userData}
      } else {
        dispatch(setWorkoutPlan(null))
        dispatch(setCurrentWorkout(null))
        dispatch(setExerciseHistory(null))
      }
      dispatch(setUser(JSON.parse(JSON.stringify(newUser))))
      if(!appIsReady) {
        setAppIsReady(true)
        SplashScreen.hideAsync();
      }
    })
  }, [])

  if (!appIsReady) {
    if(Platform.OS === 'web') {
      // Web splash screen
      return (
        <div style={{ 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100%', 
          display: 'flex',
          flexDirection: 'column',
          gap: 30
        }}>
          <Image
            source={require('./assets/logo.png')}
            resizeMode="contain"
            style={{ 
              width: Dimensions.get('window').width > 700 ? '30%' : '60%', 
              height: 'auto', 
              marginHorizontal: 'auto', 
              aspectRatio: 1 
            }}
          />
          <ActivityIndicator animating={true}/>
        </div>
      )
    } else {
      return null;
    }
  }

  return (
    <Stack.Navigator initialRouteName='Login'>
      {user ? 
        (
          <Stack.Screen name="Home" component={Home} options={{ headerShown: false }}/>
        ) : (
          <Stack.Screen name="Login" component={Login} options={{ headerShown: false }}/>
        )
      }
    </Stack.Navigator>
  )
}
export default function App() {
  return (
    <StoreProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <PaperProvider theme={customTheme}>
          <NavigationContainer
            documentTitle={{
              formatter: (options, route) => 
                `TheBodyLab`,
            }}
          >
            <Main />
          </NavigationContainer>
        </PaperProvider>
      </PersistGate>
    </StoreProvider>
  );
}

// AppRegistry.registerComponent(appName, () => App); // Comment for web
registerRootComponent(App); // Uncomment for web