import React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { CommonActions } from '@react-navigation/native'; 
import { BottomNavigation } from 'react-native-paper';
import { Platform, KeyboardAvoidingView, StatusBar } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import EvaluationRoute from './routes/EvaluationRoute';
import AppointmentRoute from './routes/AppointmentRoute';
import WorkoutRoute from './routes/WorkoutRoute';
import FoodRoute from './routes/FoodRoute';
// import ShopRoute from './routes/ShopRoute';
import ProfileRoute from './routes/ProfileRoute';

const Tab = createBottomTabNavigator();

const Home = () => {
  return (
    <>
      <KeyboardAvoidingView style={{ flex: 1 }} behavior={Platform.OS === "ios" ? "padding" : undefined}>
        <Tab.Navigator
          screenOptions={{
            headerShown: false,
          }}
          initialRouteName="workout"
          tabBar={({ navigation, state, descriptors, insets }) => (
            <BottomNavigation.Bar
              navigationState={state}
              keyboardHidesNavigationBar={true}
              safeAreaInsets={insets}
              style={{ backgroundColor: 'white', borderTopColor: 'rgb(240,238,242)', borderTopWidth: 1 }}
              onTabPress={({ route, preventDefault }) => {
                const event = navigation.emit({
                  type: 'tabPress',
                  target: route.key,
                  canPreventDefault: true,
                });

                if (event.defaultPrevented) {
                  preventDefault();
                } else {
                  navigation.dispatch({
                    ...CommonActions.navigate(route.name, route.params),
                    target: state.key,
                  });
                }
              }}
              renderIcon={({ route, focused, color }) => {
                const { options } = descriptors[route.key];
                if (options.tabBarIcon) {
                  return options.tabBarIcon({ focused, color, size: 24 });
                }

                return null;
              }}
              getLabelText={({ route }) => {
                const { options } = descriptors[route.key];
                const label =
                  options.tabBarLabel !== undefined
                    ? options.tabBarLabel
                    : options.title !== undefined
                    ? options.title
                    : route.title;

                return label;
              }}
            />
          )}
        >
          {/* <Tab.Screen
            name="shop"
            component={ShopRoute}
            options={{
              tabBarLabel: 'Loja',
              tabBarIcon: ({ color, size }) => {
                return <Icon name="shopping" size={size} color={color} />;
              },
            }}
          /> */}
          <Tab.Screen
            name="evaluation"
            component={EvaluationRoute}
            options={{
              tabBarLabel: 'Avaliação',
              tabBarIcon: ({ color, size }) => {
                return <Icon name="file-document" size={size} color={color} />;
              },
            }}
          />
          <Tab.Screen
            name="food"
            component={FoodRoute}
            options={{
              tabBarLabel: 'Alimentação',
              tabBarIcon: ({ color, size }) => {
                return <Icon name="nutrition" size={size} color={color} />;
              },
            }}
          />
          <Tab.Screen
            name="workout"
            component={WorkoutRoute}
            options={{
              tabBarLabel: 'Treino',
              tabBarIcon: ({ color, size }) => {
                return <Icon name="dumbbell" size={size} color={color} />;
              },
            }}
          />
          <Tab.Screen
            name="appointment"
            component={AppointmentRoute}
            options={{
              tabBarLabel: 'Marcações',
              tabBarIcon: ({ color, size }) => {
                return <Icon name="calendar" size={size} color={color} />;
              },
            }}
          />
          <Tab.Screen
            name="profile"
            component={ProfileRoute}
            options={{
              tabBarLabel: 'Perfil',
              tabBarIcon: ({ color, size }) => {
                return <Icon name="account" size={size} color={color} />;
              },
            }}
          />
        </Tab.Navigator>
      </KeyboardAvoidingView>
      <StatusBar style="auto" />
    </>
  );
};

export default Home;