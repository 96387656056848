import React, { useEffect, useState } from 'react';
import { View, StyleSheet, ScrollView, Animated, TouchableOpacity } from 'react-native';
import { List, Card, Title, Paragraph, ProgressBar, Text, ActivityIndicator } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkoutPlan } from '../../actions/workout';
import { setWorkoutPlan } from '../../reducers/workoutPlanReducer';
import { setCurrentWorkout } from '../../reducers/currentWorkoutReducer';
import { formatTime } from '../../utils/utils';

export default function WorkoutMain({ navigation }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const workoutPlan = useSelector((state) => state.workoutPlan.workoutPlan);
  const currentWorkout = useSelector((state) => state.currentWorkout.currentWorkout);
  const [workoutClock, setWorkoutClock] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const loadWorkouts = async () => {
      const wktPlan = await getWorkoutPlan(user?.uid)
      dispatch(setWorkoutPlan(JSON.parse(JSON.stringify(wktPlan))))
      setLoading(false)
    }

    if (!workoutPlan) {
      setLoading(true)
      loadWorkouts()
    } else {
      const currentTime = new Date().getTime() / 1000;
      if (workoutPlan.startDate > currentTime || workoutPlan.endDate < currentTime) {
        // Clear workout plan and current workout
        dispatch(setWorkoutPlan(null))
        dispatch(setCurrentWorkout(null))
      }
    }
  }, [workoutPlan])

  useEffect(() => {
    updateWorkoutTime()
  }, [currentWorkout?.startTime, currentTime])

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [currentWorkout]);

  const updateWorkoutTime = () => {
    if (currentWorkout?.startTime && currentWorkout?.startTime !== "") {
      setWorkoutClock(Math.round((new Date() - new Date(currentWorkout?.startTime)) / 1000))
    }
  }

  const handleWorkoutCardPress = (workout, index) => {
    navigation.navigate('WorkoutPage', { workout, index })
  };

  const handleCurrentWorkoutCardPress = () => {
    navigation.navigate('CurrentWorkoutPage')
  };

  if (isLoading) {
    return (
      <View style={styles.containerNoWorkouts}>
        <Text>A carregar o plano de treino...</Text>
        <ActivityIndicator animating={true} />
      </View>
    )
  }

  if (!workoutPlan || workoutPlan.workouts.length === 0) {
    return (
      <View style={styles.containerNoWorkouts}>
        <Text>Não há nenhum plano de treino ativo</Text>
      </View>
    )
  }

  return (
    <ScrollView>
      <View style={styles.container}>
        <List.Section>
          {currentWorkout && <TouchableOpacity activeOpacity={0.8} onPress={handleCurrentWorkoutCardPress}>
            <Animated.View>
              <Card style={styles.currentWorkout}>
                <Card.Content>
                  <Title style={{ color: 'white' }}>{currentWorkout.name}</Title>
                  <Paragraph style={{ color: 'white' }}>Treino em curso</Paragraph>
                  <Paragraph style={{ color: 'white' }}>{formatTime(workoutClock)}</Paragraph>
                  <ProgressBar style={{ marginTop: 10 }} progress={currentWorkout.setsDone/currentWorkout.totalSets} color='grey' />
                </Card.Content>
              </Card>
            </Animated.View>
          </TouchableOpacity>}
          <List.Subheader>Plano de Treino</List.Subheader>
            {workoutPlan.workouts.map((workout, index) => (
              <TouchableOpacity key={workout.name} activeOpacity={0.7} onPress={() => handleWorkoutCardPress(workout, index)}>
                <Animated.View>
                  <Card style={styles.card}>
                    <Card.Content>
                      <Title style={{ fontSize: 16 }}>{workout.name}</Title>
                    </Card.Content>
                  </Card>
                </Animated.View>
              </TouchableOpacity>
            ))}
        </List.Section>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 16,
    marginTop: 0
  },
  containerNoWorkouts: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    gap: 30
  },
  card: {
    marginVertical: 8,
    backgroundColor: 'white'
  },
  currentWorkout: {
    marginBottom: 10,
    backgroundColor: 'black'
  }
});