import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import FoodMain from '../pages/food/FoodMain';
import Navbar from '../components/Navbar';

const Stack = createStackNavigator();

export default function FoodRoute() {
  return (
    <Stack.Navigator 
      initialRouteName="FoodMain"
      screenOptions={{
        header: ({ navigation }) => (
          <Navbar navigation={navigation} />
        ),
      }}
    >
      <Stack.Screen name="FoodMain" component={FoodMain} />
    </Stack.Navigator>
  );
};