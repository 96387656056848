import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import WorkoutMain from '../pages/workout/WorkoutMain';
import WorkoutPage from '../pages/workout/WorkoutPage';
import CurrentWorkoutPage from '../pages/workout/CurrentWorkoutPage';
import FinishWorkoutPage from '../pages/workout/FinishWorkoutPage';
import ExerciseHistory from '../pages/workout/exercise/ExerciseHistory';
import ExerciseDetails from '../pages/workout/exercise/ExerciseDetails';
import Navbar from '../components/Navbar';

const Stack = createStackNavigator();

export default function WorkoutRoute() {
  return (
    <Stack.Navigator 
      initialRouteName="WorkoutMain"
      screenOptions={{
        header: ({ navigation }) => (
          <Navbar navigation={navigation} />
        ),
      }}
    >
      <Stack.Screen name="WorkoutMain" component={WorkoutMain} />
      <Stack.Screen name="WorkoutPage" component={WorkoutPage} />
      <Stack.Screen name="CurrentWorkoutPage" component={CurrentWorkoutPage} />
      <Stack.Screen name="FinishWorkoutPage" component={FinishWorkoutPage} />
      <Stack.Screen name="ExerciseHistory" component={ExerciseHistory} />
      <Stack.Screen name="ExerciseDetails" component={ExerciseDetails} />
    </Stack.Navigator>
  );
};