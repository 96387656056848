import React, { useState, useEffect } from 'react';
import { View, StyleSheet, ScrollView, Platform } from 'react-native';
import { Text, Button, TextInput, DataTable, ActivityIndicator } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import RNPickerSelect from 'react-native-picker-select';
import { Picker } from 'react-native';
import { setCurrentWorkout } from '../../reducers/currentWorkoutReducer'
import { formatTime } from '../../utils/utils'
import { saveWorkout } from '../../actions/workout';
import alert from '../../components/Alert';
import { setExerciseHistory } from '../../reducers/exerciseHistoryReducer';

export default function FinishWorkoutPage({ navigation, route }) {
    const { endTime } = route.params;
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);
    const workoutPlan = useSelector((state) => state.workoutPlan.workoutPlan);
    const currentWorkout = useSelector((state) => state.currentWorkout.currentWorkout);
    const [duration, setDuration] = useState("")
    const [inputValue, setInputValue] = useState('');
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        const tempDuration = endTime/1000 - (new Date(currentWorkout.startTime)).getTime()/1000
        const tempDurationMin = Math.round(tempDuration/60)
        if (tempDurationMin <= 10800) {
            setDuration(tempDurationMin*60)
        }
    }, [endTime])
    
    const handleInputChange = (text) => {
        setInputValue(text);
    };

    const getDurationOptions = () => {
        const durationOptions = []
        for (let i = 0; i < 181; i++) {
            const value = i*60
            durationOptions.push({ label: formatTime(value), value })
        }
        return durationOptions;
    }
    
    const endWorkout = async () => {
        setLoading(true)
        if(await saveWorkout(user.uid, currentWorkout, workoutPlan, duration, inputValue)) {
            setLoading(false)
            dispatch(setCurrentWorkout(null))
            dispatch(setExerciseHistory(null))
            navigation.navigate('WorkoutMain')
        } else {
            setLoading(false)
            alert('Erro', 'Ocorreu um erro ao guardar o treino. Tente novamente mais tarde', [
                { text: 'Ok', onPress: () => {} }
            ]);
        }
    }

    if(!currentWorkout) {
        return (
            <View style={styles.containerNoWorkouts}>
              <Text>Não há nenhum treino a ser executado</Text>
            </View>
        )
    }

    const getWebPickerOptions = () => {
        return getDurationOptions().map((entry) => (
            <Picker.Item key={entry.label} label={entry.label} value={entry.value}/>
        ))
    }

    return (
        <View style={{flex: 1}}>
            <ScrollView>
                <View style={styles.container}>
                    <DataTable>
                        <DataTable.Row>
                            <DataTable.Cell><Text style={styles.title}>Treino</Text></DataTable.Cell>
                            <DataTable.Cell style={{flex: 3}}>{currentWorkout.name}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell><Text style={styles.title}>Duração</Text></DataTable.Cell>
                            <DataTable.Cell style={{flex: 3}}>
                                {Platform.OS === "web" && 
                                    <Picker
                                        onValueChange={(value) => setDuration(value)}
                                        selectedValue={duration}
                                    >
                                        {getWebPickerOptions()}
                                    </Picker>
                                }
                                {Platform.OS !== "web" && 
                                    <View style={{ paddingTop: Platform.OS === "ios" ? 8 : 0}}>
                                        <RNPickerSelect
                                            onValueChange={(value) => setDuration(value)}
                                            placeholder={{
                                                label: 'Adicione uma duração...',
                                                value: "",
                                            }}
                                            style={{ minWidth: 50}}
                                            useNativeAndroidPickerStyle={false}
                                            value={duration}
                                            items={getDurationOptions()}
                                        />
                                    </View>
                                }
                            </DataTable.Cell>
                        </DataTable.Row>
                    </DataTable>
                    <TextInput
                        value={inputValue}
                        placeholder="Adicione uma nota..."
                        onChangeText={handleInputChange}
                        activeUnderlineColor="black"
                        underlineColor="black"
                        multiline={true}
                        dense={true}
                        style={styles.input}
                    />
                    <View style={styles.button}>
                        {!isLoading && <Button 
                            icon="check" 
                            style={{ backgroundColor: 'black' }}
                            labelStyle={{ color: 'white' }}
                            mode="contained-tonal" 
                            onPress={endWorkout}
                            >
                            Submeter
                        </Button>}
                        {isLoading && <ActivityIndicator animating={true} />}
                    </View>
                </View>
            </ScrollView>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: 0,
        backgroundColor: 'white'
    },
    containerNoWorkouts: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    input: {
        fontSize: 14,
        minHeight: 100,
        backgroundColor: '#ebebeb',
        justifyContent:"top",
        paddingTop: 5,
        marginHorizontal: 10
    },
    title: {
        fontWeight: 600
    },
    button: {
        marginHorizontal: 50,
        marginVertical: 20
    }
});