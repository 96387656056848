import { createSlice } from '@reduxjs/toolkit'

export const exerciseHistorySlice = createSlice({
  name: 'exerciseHistory',
  initialState: {
    exerciseHistory: {},
  },
  reducers: {
    setExerciseHistory: (state, action) => {
      if (action.payload === null) {
        state.exerciseHistory = {};
        return;
      }

      const { exerciseId, history } = action.payload;

      state.exerciseHistory = {
        ...state.exerciseHistory,
        [exerciseId]: history
      };
    },
  },
})

// Action creators are generated for each case reducer function
export const { setExerciseHistory } = exerciseHistorySlice.actions

export default exerciseHistorySlice.reducer