import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, DataTable, IconButton } from 'react-native-paper';
import { formatClock } from '../../../utils/utils'

const WorkoutExerciseInfo = ({ navigation, exercise, superset }) => {  
    return (
        <View style={styles.container}>
            <View style={styles.exerciseinfo}>
                <View style={[styles.exercise, { flex: 1 }]}>
                    <Text variant="titleMedium" style={{ paddingBottom: 3, fontWeight: superset ? 350 : 500 }}>{exercise.name}</Text>
                </View>
                <View style={styles.exercise}>
                    <IconButton 
                        icon="information-outline"
                        size={18}
                        onPress={() => navigation.navigate('ExerciseDetails', { exercise })} 
                    />
                    <IconButton 
                        icon="chart-bar"
                        size={18}
                        onPress={() => navigation.navigate('ExerciseHistory', { exercise })} 
                    />
                </View>
            </View>
            <View style={styles.tableView}>
                <DataTable>
                    <DataTable.Header>
                        <DataTable.Title style={[{flex: 1.5}, styles.title]}>Série</DataTable.Title>
                        <DataTable.Title style={[{flex: 2}, styles.title]}>Reps</DataTable.Title>
                        <DataTable.Title style={[{flex: 2}, styles.title]}>Descanso</DataTable.Title>
                    </DataTable.Header>

                    {exercise.sets.map((set, index) => (
                        <DataTable.Row key={index} style={styles.row}>
                            <DataTable.Cell style={{flex: 1.5}}>{index+1}</DataTable.Cell>
                            <DataTable.Cell style={{flex: 2}}>{set.reps}</DataTable.Cell>
                            <DataTable.Cell style={{flex: 2}}>{formatClock(set.rest)}</DataTable.Cell>
                        </DataTable.Row>
                    ))}
                </DataTable>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white'
    },
    title: {
        paddingVertical: 5,
    }, 
    exerciseinfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    exercise: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    tableView: {
        marginHorizontal: 30,
    },
    row: {
        minHeight: 25,
        padding: 5
    }
});

export default WorkoutExerciseInfo;