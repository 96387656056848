import React from 'react';

const WebYoutube = ({ videoId }) => {
    return (
      <div>
        <iframe
          width="100%"
          height="300"
          src={`https://www.youtube.com/embed/${videoId}`}
          frameBorder="0"
          allowFullScreen
        />
      </div>
    );
};

export default WebYoutube;